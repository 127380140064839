<template>
  <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.508 0.405708C13.3203 0.255277 13.104 0.142849 12.8716 0.0749307C12.6393 0.00701241 12.3955 -0.0150468 12.1544 0.0100299C11.9133 0.0351066 11.6797 0.106821 11.4671 0.221022C11.2544 0.335223 11.067 0.489643 10.9157 0.675342L5.60633 7.19691H7.953L12.3475 1.79702L13.7592 2.92769L10.2208 7.19691H12.5822L15.18 4.06196L18.3297 6.58394L17.7833 7.19691H18.3333C18.8801 7.19635 19.4238 7.27571 19.9467 7.43239C20.1464 7.06502 20.2099 6.64108 20.1263 6.23292C20.0427 5.82477 19.8172 5.45771 19.4883 5.19442L13.508 0.405708ZM1.83333 8.09569C1.83333 7.85732 1.92991 7.62871 2.10182 7.46016C2.27373 7.2916 2.50688 7.19691 2.75 7.19691H3.773L5.2305 5.39934H2.75C2.02065 5.39934 1.32118 5.68342 0.805456 6.18909C0.289731 6.69475 0 7.38058 0 8.09569V18.8811C0 20.0729 0.482886 21.216 1.34243 22.0588C2.20197 22.9015 3.36776 23.375 4.58333 23.375H18.3333C19.3058 23.375 20.2384 22.9962 20.9261 22.322C21.6137 21.6478 22 20.7334 22 19.7799V12.5896C22 11.6361 21.6137 10.7217 20.9261 10.0475C20.2384 9.37325 19.3058 8.99448 18.3333 8.99448H2.75C2.50688 8.99448 2.27373 8.89978 2.10182 8.73123C1.92991 8.56267 1.83333 8.33407 1.83333 8.09569ZM15.5833 16.1847H17.4167C17.6598 16.1847 17.8929 16.2794 18.0648 16.448C18.2368 16.6165 18.3333 16.8451 18.3333 17.0835C18.3333 17.3219 18.2368 17.5505 18.0648 17.7191C17.8929 17.8876 17.6598 17.9823 17.4167 17.9823H15.5833C15.3402 17.9823 15.1071 17.8876 14.9352 17.7191C14.7632 17.5505 14.6667 17.3219 14.6667 17.0835C14.6667 16.8451 14.7632 16.6165 14.9352 16.448C15.1071 16.2794 15.3402 16.1847 15.5833 16.1847Z" :fill="color || '#F5831F'"/>
  </svg>
</template>

<script>
export default {
  name: 'CreditIcon',
  props: ['color'],
}
</script>

<style>

</style>
