<template>
  <div class="text-dark">
    <b-modal id="modal-response" v-model="modalVisible" centered class="p-3">
      <div slot="modal-header" class="d-flex align-items-center justify-content-between p-2 w-100">
        <span class="h4 mb-0" style="color: var(--biz-primary-100);">Rincian Pembayaran</span>
        <span @click="modalVisible = false" class="h4 mb-0 text-hover" style="color: var(--biz-primary-100);">x</span>
      </div>
      <div class="d-flex align-items-center justify-content-between p-2">
        <span class="text-dark">Total Harga {{ `(${totalItems || 0} Produk)` }}</span>
        <span style="color: var(--biz-secondary-100);">Rp. {{ toCurrency(dataFetch.sub_total) }}</span>
      </div>
      <div v-if="discountTransaction.length > 0" >
        <div v-for="(item, index) in discountTransaction" :key="index">
          <div class="d-flex align-items-center justify-content-between p-2">
            <span class="text-dark"> {{ item.name }} </span>
            <span style="color: #FF0000"> - Rp {{ toCurrency(item.promotion_value) }} </span>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between p-2">
        <span class="text-dark">Credit Limit</span>
        <span style="color: var(--biz-secondary-100);"> - Rp. {{ toCurrency(dataFetch.payment_data.credit_limit_usage) }}</span>
      </div>
      <div slot="modal-footer" class="d-flex align-items-center justify-content-between w-100 p-2">
        <span class="text-dark bold">Total Tagihan</span>
        <span class="bold" style="color: var(--biz-secondary-100);">Rp. {{ toCurrency(grossTotal - dataFetch.payment_data.credit_limit_usage) }}</span>
      </div>
    </b-modal>
    <h2 class="mb-5">Pembayaran</h2>
    <div class="d-flex justify-content-center">
      <div class="card align-items-center w-75" style="padding: 24px 100px">
        <div class="card-body col">
          <div class="d-flex mb-5" style="gap: 0px 20px">
            <div class="d-flex align-items-center">
              <CreditIcon :color="'var(--biz-secondary-100)'"/>
              <div class="ml-2" style="color: var(--biz-primary-100);">Credit Limit</div>
            </div>
            <span>- Rp. {{ toCurrency(dataFetch.payment_data.credit_limit_usage) }}</span>
          </div>
          <div class="d-flex justify-content-between mb-5">
            <div class="d-flex flex-column">
              <span>Total Pembayaran</span>
              <div class="d-flex align-items-center justify">
                <span style="color: var(--biz-secondary-100);">Rp. {{ toCurrency(grossTotal - dataFetch.payment_data.credit_limit_usage) }}</span>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-end">
              <span class="text-hover button-link" style="color: var(--biz-primary-100);" @click="toggleModal">Lihat Rincian Pembayaran</span>
            </div>
          </div>
          <div class="card border-0 shadow-none d-flex align-items-end" style="gap: 20px 0px;">
            <div
              @click="$router.push({ path: '/purchase/' })"
              class="btn d-flex rounded align-self-end align-items-center justify-content-between mb-3 button-history"
            >
              <Note :color="'var(--biz-primary-100)'"/>
              <span class="mx-2">Lihat Daftar Transaksi</span>
              <span class="h4 mb-0">
                <i class="fe fe-chevron-right font-size-18" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import CreditIcon from '@/components/Icons/Credit.vue'

export default {
  name: 'PaymentFullCredit',
  components: {
    CreditIcon,
  },
  props: ['grossTotal', 'totalItems', 'dataFetch'],
  data: function () {
    return {
      id_params: this.$route.params.order_id,
      data: {},
      activeKey: '',
      modalVisible: false,
      countDown: moment().format('HH:mm:ss'),
      discountTransaction: [],
    }
  },
  computed: {
    time: function () {
      const time = this.countDown
      const resultTime = moment.utc(time).format('HH:mm:ss')
      return this.countDown <= 0 ? '00:00:00' : resultTime
    },
  },
  methods: {
    getTotalProductDiscount(obj, value, type) {
      const filter = this.discountProduct?.[0]?.data?.filter(v => { return v.catalog_id === obj.catalog_id })
      if (this.discountProduct.length > 0 && filter.length > 0) {
        if (type === 'total') {
          return value - filter[0].value
        } else {
          return Math.round(((obj.selling_price * obj.qty) - filter[0].value) / obj.qty)
        }
      } else {
        return value
      }
    },
    getDifferenceInHours(expiredDate, currentDate) {
      const differentTime = expiredDate - currentDate
      const checkIfExpired = differentTime <= 0 ? 0 : moment(expiredDate) - moment(currentDate)
      // const result = moment.utc(checkIfExpired).format('HH:mm:ss')
      return checkIfExpired
    },
    getOrderDetail() {
      this.$store.dispatch('purchase/GETINTRUCTIONSPAYMENT', {
        channel_id: this.$store.state.user.user_data.channel_id,
        order_id: this.id_params,
      })
        .then(({ data }) => {
          const splitDate = data.expired_at.split(' ')
          const endDate = {
            day: moment(splitDate[0]).format('dddd'),
            date: moment(splitDate[0]).format('DD MMMM YYYY'),
            hour: moment(splitDate[1], 'HH:mm:ss').format('HH:mm'),
            countDown: [splitDate[0], splitDate[1]].join(' '),
          }
          const expiredDate = moment(endDate.countDown).format('YYYY-MM-DD HH:mm:ss')
          const hours = this.getDifferenceInHours(new Date(expiredDate).getTime(), new Date().getTime())
          this.countDown = hours
          const newInstructions = (data && data.instructions) || (data && data.instructions && data.instructions.instructions) || {}
          return this.$store.dispatch('purchase/GETINVOICEDETAIL', {
            order_id: this.id_params,
          }).then((res) => {
            const result = res.data
            this.data = {
              ...data,
              ...result,
              newInstructions,
              expired: `${endDate.day}, ${endDate.date} - ${endDate.hour}`,
              total: Intl.NumberFormat('en-US').format(result.gross_amount),
            }
            this.discountTransaction = result?.promotions?.discounts
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    changeActiveKey(key) {
      this.activeKey = key
    },
    toggleModal() {
      this.modalVisible = !this.modal
    },
    onCopy() {
      return this.$notification.success({
        message: 'Copy to Clipboard Success',
      })
    },
    onError() {
      return this.$notification.error({
        message: 'Copy to Clipboard Failed',
      })
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
  },
  mounted() {
    this.getOrderDetail()
    setInterval(() => {
      this.countDown = moment(this.countDown).subtract(1, 'seconds')
    }, 1000)
  },
  watch: {
    // this.countDownTimer()
  },
}
</script>

<style scoped>
.ant-collapse-header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
}

.text-hover:hover {
  color: var(--biz-primary-100);;
  cursor: pointer;
}
.button-link {
  font-size: 14px;
}

.button-primary {
  padding: 4px 24px;
  border-radius: 8px;
  font-size: 16px;
}
.bold {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}
.button-history {
  background-color: var(--biz-brand-base);
  border: solid 1px var(--biz-primary-100);
  color: var(--biz-primary-100) !important;
  &:hover {
    background-color: var(--biz-primary-100);
    color: var(--biz-brand-base) !important;
    border: solid 1px var(--biz-brand-base);
  }
}
</style>
